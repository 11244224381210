import React, { useEffect, useMemo, useRef, useContext, useState } from "react";
import {
  Accordion,
  Card,
  Row,
  Col,
  Button,
  AccordionContext,
  useAccordionToggle,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import ModalDommus from "../../../../components/Modal";
import ModalEmail from "./ModalEmail";
import Swal from "sweetalert2";
import caAPI from "../../../../services/caAPI";
import { Interweave, Node } from "interweave";
import "./style.scss";
// npm install --save-dev @iconify/react @iconify-icons/icomoon-free
import { Icon } from "@iconify/react";
import mailSendLine from "@iconify/icons-ri/mail-send-line";
import InfiniteScroll from "react-infinite-scroll-component";
import { WebSocketContext } from "../../../../contexts/WebSocketContext";
import { UrlMatcher } from 'interweave-autolink';
import { Templates } from "./templates";
import { trackPromise } from "react-promise-tracker";
import { faArrowAltCircleLeft, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <div
      style={{
        backgroundColor: isCurrentEventKey ? "#265d9c" : "rgba(0,0,0,.03)",
        color: isCurrentEventKey ? "white" : "black",
        padding: ".75rem 1.25rem",
        marginbottom: "0",
        borderbottom: "1px solid rgba(0,0,0,.125)",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  );
}

export default function EmailComponent({ atendimento, AtualizarListaEmails }) {
  const [listaMensagens, setListaMensagens] = useState([]);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const messageContainerRef = useRef();
  const [atendimentoAtivo, setAtendimentoAtivo] = useState(atendimento);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [assinatura, setAssinatura] = useState("");
  const [iniciado, setIniciado] = useState(false);
  const [modalTemplateAberto, setModalTemplateAberto] = useState(false);

  const handleDownloadAttachment = (attachment) => {
    caAPI.atendimento.email.getAnexo(attachment.file_name).then((res) => {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = res.data.url;
      a.click();
    });
  };

  const transformarAnchor = (node: HTMLElement, children: Node[]) => {
      if (node.tagName === 'a' || node.tagName === 'A') {
          return <a href={'https://' + node.getAttribute('href')} target="_blank">{children}</a>;
      }
  };

  useEffect(() => {
    caAPI.atendimento.email
      .getAssinatura()
      .then((res) => {
        setAssinatura(
          res.data.assinatura
            ? JSON.parse(res.data.assinatura)
            : res.data.assinatura
        );
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar assinatura",
          icon: "error",
        });
        return;
      });
    setIniciado(!!atendimento.conteudos?.length)
    setAtendimentoAtivo(atendimento);
  }, [atendimento]);

  useEffect(() => {
    setListaMensagens(atendimentoAtivo.conteudos);
    setHasMore(atendimentoAtivo.hasMore);
  }, [atendimentoAtivo]);

  const { channel } = useContext(WebSocketContext);
  useEffect(() => {
    channel.unbind('email.' + atendimentoAtivo.id);
    channel.bind("email." + atendimentoAtivo.id, function (data) {
      const newMessage = JSON.parse(data.mensagem);
      setListaMensagens((messages) => [...messages, newMessage]);
    });
  }, [channel, atendimentoAtivo]);

  const handleLoadMoreMessages = () => {
    const messages = [...listaMensagens];

    caAPI.atendimento
      .getMessages(atendimento.id, Number(currentPage) + 1)
      .then((res) => {
        messages.push(res.data);
        setCurrentPage(res.data.currentPage);
        setListaMensagens([...listaMensagens, ...res.data.conteudos]);
        setHasMore(res.data.hasMore);
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao buscar mais mensagens",
          icon: "error",
        });
        return;
      });
  };
  
  const mensagens = useMemo(() => {
    if (listaMensagens) {
      return listaMensagens.map((msg) => {
        let detalhes = JSON.parse(msg.detalhes || '{}').data;
        return (
          <div className="email" key={msg.id}>
            <div className="header email-header">
              <Card>
                <Accordion>
                  <div className="accordion_iten">
                    <ContextAwareToggle
                      as={Card.Header}
                      cardName={msg?.id}
                      title={detalhes?.assunto}
                      eventKey={msg?.id}
                    >
                      <Row>
                        <Col>
                          <div className="dadosAssunto">
                            {msg?.direcao === "enviado" ? (
                              <Icon
                                className="iconeEnviadoRecebido"
                                icon={mailSendLine}
                              />
                            ) : (
                              <Icon
                                className="iconeEnviadoRecebido"
                                icon={mailSendLine}
                                hFlip={true}
                              />
                            )}
                            {detalhes?.assunto}
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="data">
                            {moment(msg.criado_em).format("DD/MM/YYYY HH:mm")}
                          </div>
                        </Col>
                      </Row>
                    </ContextAwareToggle>
                  </div>
                  <Accordion.Collapse eventKey={msg.id}>
                    <div className="dadosEnvio">
                      {msg.direcao === "enviado" ? (
                        <div className="dadosPara">Para: {detalhes?.to ?? "---"} </div>
                      ) : (
                        <div className="dadosDe">De: {detalhes?.from ?? "---"}</div>
                      )}
                      {detalhes?.cc ? (
                        <div className="dadosCopiaCortesia">
                          CC:{" "}
                          <span className="emailCopiaCortesia">
                            {" "}
                            {detalhes.cc}{" "}
                          </span>{" "}
                        </div>
                      ) : (
                        <></>
                      )}
                      {detalhes?.bcc ? (
                        <div className="dadosCopiaCortesiaOculta">
                          CCO:{" "}
                          <span className="emailCopiaCortesiaOculta">
                            {detalhes.bcc}{" "}
                          </span>{" "}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </Card>
            </div>
            <div className="body" style={{ overflow: "hidden" }}>
              <Interweave content={msg.conteudo?.substring(0, 1000)} transform={transformarAnchor} matchers={[new UrlMatcher('url', { validateTLD: false })] || ''}/>
              {detalhes?.attachments && (
                <div style={{ backgroundColor: "#ebebeb" }}>
                  {detalhes.attachments.map(function (attachment, indice) {
                    return (
                      <span
                        key={indice}
                        onClick={() => handleDownloadAttachment(attachment)}
                        style={{
                          padding: "15px",
                          cursor: "pointer",
                          fontSize: "0.8em",
                        }}
                      >
                        {attachment.file_name}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
      });
    }
    messageContainerRef.current.scrollIntoView({ behavior: "smooth" });
  }, [listaMensagens]);

  function enviarMensagemTemplate(mensagemInicial) {
    const dadosEmail = { 
      id_atendimento: atendimentoAtivo.id, 
      body: mensagemInicial.mensagem, 
      template: true, 
      id_template:mensagemInicial.id_template
    }
    trackPromise(caAPI.atendimento.email.enviar(dadosEmail))
      .then(res => {
        const novaMensagem = res.data
        setListaMensagens((messages) => [novaMensagem, ...messages]);
        setIniciado(true);
        setModalTemplateAberto(false)
      }).catch(err => {
        console.log(err)
        console.log(err.response)
        if(err.response?.status === 422) {
          Swal.fire('Ooops...', err.response?.data?.message, 'warning')

          return
        }
        Swal.fire({
          titleText: "Erro",
          text: "Ocorreu um problema ao iniciar o atendimento.",
          icon: 'error'
        });
      })
  }

  return (
    <>
      <div className="email-container" id="scrollableDiv">
        {(!iniciado && <Templates enviarMensagemTemplate={enviarMensagemTemplate}/>) || <></>}
        <InfiniteScroll
          className="email-container"
          style={{ display: "flex", flexDirection: "column-reverse" }}
          dataLength={mensagens?.length}
          next={handleLoadMoreMessages}
          hasMore={hasMore}
          inverse={true}
          loader={<Spinner animation="border" style={{ color: "#265d9c" }} />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Fim das Mensagens</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={() => { }}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          scrollableTarget="scrollableDiv"
        >
          {mensagens}
        </InfiniteScroll>
      </div>
      <div ref={messageContainerRef}></div>
      <div className="footer">
        <Row>
          <Col>
            <Button className="btnEnviarEmail" onClick={() => setOpenModalEmail(true)}>
              <Icon icon={mailSendLine} fontSize={20} style={{ position: 'relative', top: '-1' }}/> Enviar Email
            </Button>
          </Col>
          {(iniciado && (
            <Col>
              <Button variant="info" className="btnEnviarTemplate" onClick={() => setModalTemplateAberto(true)}>
                <FontAwesomeIcon icon={faLayerGroup}/> Enviar Template
              </Button>
            </Col>
          )) || <></>}
        </Row>
      </div>
      <ModalDommus
        titulo="Enviar Email"
        open={openModalEmail}
        close={() => setOpenModalEmail(false)}
      >
        <ModalEmail
          assinatura={assinatura}
          AtualizarListaEmails={AtualizarListaEmails}
          setOpenModalEmail={setOpenModalEmail}
          atendimento={atendimento}
        />
      </ModalDommus>
      <ModalDommus
        titulo="Enviar Email Template"
        open={modalTemplateAberto}
        close={() => setModalTemplateAberto(false)}
      >
        <div className="templates-scrolaveis px-3">
          <Row className="px-3">
            <Col className="p-3 rounded border" style={{ backgroundColor: '#F2F3F8' }}>
              <Templates enviarMensagemTemplate={enviarMensagemTemplate}/>
            </Col>
          </Row>
          <Row>
            <Col className="p-3 text-right">
              <Button variant="secondary" onClick={() => setModalTemplateAberto(false)}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft}/> Voltar
              </Button>
            </Col>
          </Row>
        </div>
      </ModalDommus>
    </>
  );
}

import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import Swal from 'sweetalert2'
import { WebSocketContext } from "../../../../contexts/WebSocketContext";
import caAPI from "../../../../services/caAPI";
import './style.scss';
import moment from "moment";
import { InputMensagens } from '../../../../components/InputMensage';
import Templates from './templates';
import MensagemConteudoAtendimentoWhatsapp from '../../../../components/MensagemConteudoAtendimentoWhatsapp';
import InfiniteScroll from "react-infinite-scroll-component";
import { AtendimentoContext } from '../../../../contexts/AtendimentoContext';
import { AtendentesContext } from '../../../../contexts/AtendentesContext';

const templateMensagens = 'whatsapp';

export default function WhatsappComponent() {

    const [mensagemInput, setMensagemInput] = useState('');
    const [iniciado, setIniciado] = useState(false);
    const [enviarTemplate, setEnviarTemplate] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const messageContainerRef = useRef();
    const messagemOverflowRef = useRef();
    const [arquivos, setArquivos] = useState({});
    const [disabledInputMessage, setDisabledInputMessage] = useState(false);
    const {atendimentoAtivo, setLoadingMensagens, mensagensAtualizadasWhatsapp} = useContext(AtendimentoContext);
    const [listaMensagens, setListaMensagens] = useState(new Map());
    const { channel } = useContext(WebSocketContext);
    const {testarUsuarioAtendeInputMensagem} = useContext(AtendentesContext)

    function recebeMensagemInput(e) {
        setMensagemInput(e);
    }

    function recebeArquivo(e) {
        setArquivos(e);
    }

    function parseMap(lista){
        let map = new Map();
        lista.map((item)=>{
           return map.set(Number(item?.id), item);
        })
        return map;
    }

    useEffect(() => {
        messagemOverflowRef.current.scrollTo(0,0);
    }, [atendimentoAtivo])


    useEffect(() => {
      setIniciado(false)
      setDisabledInputMessage(false);
      setEnviarTemplate(false);
      setListaMensagens(new Map());
      setHasMore(false);
      if(atendimentoAtivo){
        const novaLista = atendimentoAtivo.conteudos || [];
        const novoHasMore = atendimentoAtivo.hasMore;
        let horaAtual = moment();
        let novoDisabledInputMessage = true;
        let novoIniciado = false;
        let novoEnviarTemplate = false;
        if(novaLista.length > 0){
          novoIniciado = true;
          const ultimoConteudo = novaLista[novaLista.length - 1];
          const verificaMensagemRecebidaDentroDaJanela = (conteudo) => {
            let horaAtendimento = moment(conteudo.criado_em);
            return conteudo.direcao === "recebido" && (horaAtual.diff(horaAtendimento, "hours") < 24);
          }
          let detalhesUltimaMensagem = JSON.parse(ultimoConteudo.detalhes)
          let horaUltimaMensagemTemplate = moment(ultimoConteudo.criado_em);
          if (atendimentoAtivo.ultimo_recebido && verificaMensagemRecebidaDentroDaJanela(atendimentoAtivo.ultimo_recebido)) {
            novoDisabledInputMessage = false;
          }else if((atendimentoAtivo.situacao_atendimento && atendimentoAtivo.situacao_atendimento.tipo !== 'F') && !(detalhesUltimaMensagem.template && horaAtual.diff(horaUltimaMensagemTemplate, "hours") < 24)){
            novoEnviarTemplate = true;
          }
        }else{
          novoEnviarTemplate = true;
        }
        setIniciado(novoIniciado)
        let idUsuario = Number(caAPI.getLocalState("idUsuario"));
        if(Number(atendimentoAtivo.atendente) !== idUsuario && !testarUsuarioAtendeInputMensagem(atendimentoAtivo)){
          novoDisabledInputMessage = true;
        }
        setDisabledInputMessage(novoDisabledInputMessage);
        setEnviarTemplate(novoEnviarTemplate);
        setListaMensagens(parseMap(novaLista));
        setHasMore(novoHasMore);
        setCurrentPage(1);

      }
      return () => {
        setIniciado(false)
        setDisabledInputMessage(false);
        setEnviarTemplate(false);
        setListaMensagens(new Map());
        setHasMore(false);
      }
    }, [atendimentoAtivo]);

    useEffect(() => {
        channel.unbind('whatsapp.' + atendimentoAtivo.id);
        channel.bind('whatsapp.' + atendimentoAtivo.id, function (data) {
          const newMessage = JSON.parse(data.mensagem);
          setListaMensagens(mensagens => new Map(mensagens).set(Number(newMessage?.id), newMessage));
          disabledInputMessage && setDisabledInputMessage(false)
        });

        return () => {
          channel.unbind('whatsapp.' + atendimentoAtivo.id);
        }

    }, [channel, atendimentoAtivo, disabledInputMessage ])

    const enviarMensagem = useCallback(() => {
        if (!mensagemInput) {
            Swal.fire({
                titleText: "Atenção",
                text: "Escreva a mensagem antes de enviar",
                icon: 'warning'
            });
            return;
        }
        trackPromise(
            caAPI.atendimento.whatsapp.enviar({ id_atendimento: atendimentoAtivo.id, body: mensagemInput }).then(res => {
              const novaMensagem = (res.data.conteudos.pop());
              setListaMensagens(messages => new Map(messages).set(Number(novaMensagem.id), novaMensagem));
            }).catch(e => {
                Swal.fire({
                    titleText: "Erro",
                    text: "Houve um problema ao enviar a mensagem " + e,
                    icon: 'error'
                });
            })
        )
        setMensagemInput('');
    }, [atendimentoAtivo, mensagemInput]);

    const enviarImagem = useCallback((arquivosGaleria=null) => {
        const arquivosEnviar = arquivosGaleria || arquivos
        if (!arquivosEnviar?.length) {
            Swal.fire({
                titleText: "Atenção",
                text: "Nenhuma imagem selecionada",
                icon: 'warning'
            });
            return;
        }
        arquivosEnviar.forEach((arquivo, idx) => {
            let payload = {
                id_atendimento: atendimentoAtivo.id,
                body: mensagemInput
            };
            payload.nome_arquivo = arquivo.nome || null;
            if(arquivosGaleria) {
                payload.url_media = arquivo.link;
                payload.tipo = arquivo.extensao;
                payload.body = arquivo.nome || null;
            } else {
                payload.arquivo = arquivo.conteudo
                payload.tipo = arquivo.tipo
            }
            trackPromise(
                caAPI.atendimento.whatsapp.enviar(payload)
                    .then(res => {
                        if(idx != arquivosEnviar?.length -1) {
                            return
                        }
                        const novaMensagem = res.data.conteudos.pop();
                        setListaMensagens(menssages => new Map(menssages).set(Number(novaMensagem.id), novaMensagem));
                        setMensagemInput('');
                    }).catch(e => {
                        Swal.fire({
                            titleText: "Erro",
                            text: "Houve um problema ao enviar a mensagem " + e,
                            icon: 'error'
                        });
                    })
            )
        })
        setArquivos("");
    }, [atendimentoAtivo, arquivos]);

    function recebeMensagemInicial(mensagemInicial) {
        trackPromise(
            caAPI.atendimento.whatsapp.enviar({ id_atendimento: atendimentoAtivo.id, body: mensagemInicial.mensagem, template: true, id_template:mensagemInicial.id_template}).then(res => {
              const novaMensagem = res.data.conteudos.pop();
              setListaMensagens(menssages => new Map(menssages).set(Number(novaMensagem.id), novaMensagem));
              setIniciado(true);
              setEnviarTemplate(false);
            })).catch(err => {
                console.log(err)
                console.log(err.response)
                if(err.response?.status == 422) {
                    Swal.fire('Ooops...', err.response?.data?.message, 'warning')

                    return
                }
                Swal.fire({
                    titleText: "Erro",
                    text: "Ocorreu um problema ao iniciar o atendimento.",
                    icon: 'error'
                });
            })
    }

    const changeSubmitKey = useCallback(e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            enviarMensagem();
        }
    }, [enviarMensagem]);

    const scrollDown = function () {
        messageContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const mensagens = useMemo(() => {
        if (listaMensagens) {
            let listIterar = Array.from(listaMensagens.values()).filter(msg => msg && msg?.detalhes).reverse();
            let date = moment().format('DD/MM/YYYY');;
            setLoadingMensagens(false);
            return listIterar.map((msg, indice) => {
                let isLast = (indice === (listIterar.length - 1));
                let separator = false;
                let separatorLast = false;

                let msg_date = moment(msg.criado_em).format('DD/MM/YYYY');
                if ((date !== msg_date)) {
                    separator = <div className="separador">{date}</div>;
                    date = msg_date;
                }
                if(isLast){
                  separatorLast = <div className="separador">{msg_date}</div>;
                }
                return <Fragment key={msg.id}>
                  {separator}
                  <MensagemConteudoAtendimentoWhatsapp conteudo={msg}/>
                  {separatorLast}
                </Fragment>
            });
        }

        scrollDown()
    }, [listaMensagens]);

    const handleLoadMoreMessages = () => {
        setLoadingMensagens(true);
        caAPI.atendimento.getMessages(atendimentoAtivo.id, Number(currentPage) + 1).then(res => {
            setCurrentPage(currentPage + 1)
            let contatenado = new Map([...(parseMap(res.data.conteudos))].concat([...listaMensagens]))
            setListaMensagens(contatenado);
            setHasMore(res.data.hasMore);
            setLoadingMensagens(false);
        }).catch(err => {
            Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao buscar mais mensagens",
                icon: 'error'
            });
            return;
        });

    };

    useEffect(() => {
      if(mensagensAtualizadasWhatsapp){
        const mensagensInserir = mensagensAtualizadasWhatsapp.filter(mensagem => mensagem && mensagem.id_atendimento == atendimentoAtivo.id);
        if(mensagensInserir.length){
          const novoMensagens = Array.from(listaMensagens.values()).concat(mensagensInserir);
          novoMensagens.sort((mensagemA, mensagemB) => {
            return Date(mensagemA.criado_em) < Date(mensagemB.criado_em) ? -1 : 1;
          });
          setListaMensagens(parseMap(novoMensagens));
        }
      }
    }, [mensagensAtualizadasWhatsapp])

    return (
        <>
            <div className="whatsapp-container" id="scrollableDiv" ref={messagemOverflowRef} >
            { enviarTemplate &&
              <>
                  <Templates recebeMensagemInicial={recebeMensagemInicial}/>
              </>
            }
                {iniciado &&
                    <>
                        <InfiniteScroll
                            useWindow={false}
                            className="whatsapp-container"
                            style={{ display: 'flex', flexDirection: 'column-reverse' }}
                            dataLength={mensagens.length}
                            next={handleLoadMoreMessages}
                            hasMore={hasMore}
                            inverse={true}
                            loader={<Spinner animation="border" style={{ color: '#265d9c' }} />}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>Fim das Mensagens</b>
                                </p>
                            }
                            // below props only if you need pull down functionality
                            refreshFunction={() => { }}
                            pullDownToRefresh
                            pullDownToRefreshThreshold={50}
                            scrollableTarget="scrollableDiv"

                        >
                            {mensagens}
                        </InfiniteScroll>
                    </>
                  }

            </div>
            <div ref={messageContainerRef} />
            <div className='mensagem-textarea'>
                {(iniciado && !(enviarTemplate) && (atendimentoAtivo.situacao_atendimento && atendimentoAtivo.situacao_atendimento.tipo !== 'F')) &&
                    <InputMensagens
                        disabledInputMessage={disabledInputMessage}
                        disabledEmoticons={disabledInputMessage}
                        disabledAnexos={disabledInputMessage}
                        disabledAnotacoes={disabledInputMessage}
                        disabledMenu={false}
                        templateMensagens={templateMensagens}
                        mensagemInput={mensagemInput}
                        setLegendaInput={setMensagemInput}
                        recebeArquivo={recebeArquivo}
                        enviarImagem={enviarImagem}
                        enviarMensagem={enviarMensagem}
                        receberMensagemInicial={recebeMensagemInicial}
                        atendimento={atendimentoAtivo}
                        recebeMensagemInput={recebeMensagemInput}
                        changeSubmitKey={changeSubmitKey}
                        applyRule={false}/>
                }
            </div>
        </>
    );
}

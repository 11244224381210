import React, { useEffect, useState } from "react";
import { faExclamationTriangle, faFilePdf, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Form, Row } from "react-bootstrap";
import './styles.css'
import ReactTooltip from "react-tooltip";
import { useForm } from "react-hook-form";
import { Icon } from '@iconify/react';
import sendOutlined from '@iconify-icons/ant-design/send-outlined';
import DommusToggle from "../DommusToggle";
import Anexos from "../Anexos";
import caAPI from "../../services/caAPI";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import {isMobile} from 'react-device-detect';

export function Galeria({ tipo, definirEstadoModal, enviarArquivos }) {
    const { register, setValue, handleSubmit, getValues } = useForm()
    const [toggleEnviarArquivosAberto, setToggleEnviarArquivosAberto] = useState(true)
    const [toggleSalvarArquivosAberto, setToggleSalvarArquivosAberto] = useState(true)
    const [atualizarTooltip, setAtualizarTooltip] = useState(false)
    const [arquivos, setArquivos] = useState([])
    const [arquivosSalvar, setArquivosSalvar] = useState([])

    useEffect(() => {
        caAPI.arquivoAtendimento.buscar({ tipo })
            .then((response) => setArquivos(response.data))
            .catch(error => {
                console.log(error)
                console.log(error?.response?.data)
                Swal.fire('Ooops...', 'Houve um erro ao tentar buscar os arquivos.', 'error')
            })
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [atualizarTooltip])

    function marcarDesmarcarArquivo(idArquivo) {
        const nome = resolverNomeFormulario(idArquivo)
        setValue(nome, !getValues(nome))
        setAtualizarTooltip(atualizarTooltip => !atualizarTooltip)
    }

    function resolverNomeFormulario(idArquivo) {
        return `${tipo}-${idArquivo}`
    }

    function salvarImagens() {
        if(!arquivosSalvar.length) {
            Swal.fire('Nenhum arquivo selecionado!', 'Selecione pelo o menos um arquivo antes de salvar.', 'warning')
            return
        }
        const arquivosTratados = arquivosSalvar.map(arquivo => {
            arquivo.extensao = arquivo.tipo
            arquivo.tipo = tipo

            return arquivo
        })
        caAPI.arquivoAtendimento.salvar(arquivosTratados)
            .then((response) => {
                definirEstadoModal(false)
                setArquivosSalvar([])
                Swal.fire('Sucesso!', 'Arquivos salvos com sucesso.', 'success').then(() => definirEstadoModal(true))
            }).catch(error => {
                console.log(error)
                console.log(error?.response?.data)
                Swal.fire('Ooops...', 'Houve um erro ao tentar salvar os arquivos.', 'error')
            })
    }

    function enviarArquivosSelecionados(arquivosSelecionados) {
        if (!arquivosSelecionados) {
            Swal.fire({
                titleText: "Atenção",
                text: `${tipo == 'imagem' ? 'Nenhuma imagem selecionada' : 'Nenhum anexo selecionado'}`,
                icon: 'warning'
            });
            return;
        }
        const arquivosSelecionadosFiltrados = Object.entries(arquivosSelecionados).filter(([nome, selecionado]) => selecionado == true);
        const idsArquivosSelecionados = Object.values(arquivosSelecionadosFiltrados).map(arquivo => Number(arquivo[0].split('-')[1]))
        const arquivosSelecionadosTratados = arquivos.filter(arquivo => idsArquivosSelecionados.includes(arquivo.id_arquivo_atendimento))
        enviarArquivos(arquivosSelecionadosTratados)
        definirEstadoModal(false)
    }

    function excluirArquivo(idArquivo) {
        Swal.fire({
            titleText: "Deseja excluir o arquivo?",
            text: "Confime a realização da ação.",
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim, excluir!",
            cancelButtonText: "Não, mantenha.",
            customClass: {
              confirmButton: "btn btn-primary mr-3",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (!result.isConfirmed) {
                return
            }
            trackPromise(caAPI.arquivoAtendimento.excluir(idArquivo))
                .then(() => {
                    definirEstadoModal(false)
                    setArquivosSalvar([])
                    Swal.fire('Sucesso!', 'Arquivo excluído com sucesso.', 'success').then(() => definirEstadoModal(true))
                }).catch(error => {
                    console.log(error)
                    console.log(error?.response?.data)
                    Swal.fire('Ooops...', 'Houve um erro ao tentar excluir o arquivo.', 'error')
                })
        })
    }

    function Imagem({ arquivo }) {
        return (<>
            <div className="botoesEditarFoto">
                <div className="checkbox-imagem-galeria rounded py-1">
                    <Form.Check
                        type="checkbox"
                        className="p-0 m-0"
                        {...register(resolverNomeFormulario(arquivo.id_arquivo_atendimento))}
                        data-tip={`${getValues(resolverNomeFormulario(arquivo.id_arquivo_atendimento)) ? 'Desconsiderar' : 'Selecionar'} Imagem`}
                        style={{cursor: 'pointer'}}
                    />
                </div>
                {/* <Button
                    className="btn-sm btn-warning"
                    data-tip={`Enviar Imagem`}
                    style={{ backgroundColor: 'var(--dommus-color-2)' }}
                    onClick={(event) => event.stopPropagation()}
                >
                    <Icon icon={sendOutlined} color="white" />
                </Button> */}
                <Button className="btn-sm btn-danger" data-tip={`Excluir Imagem`} onClick={(event) => {
                    event.stopPropagation()
                    excluirArquivo(arquivo.id_arquivo_atendimento)
                }}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </Button>
            </div>
            <img className="modalImgPortal" src={arquivo.link} alt="Arquivo para enviar no whatsapp" width={4} height={3}></img>
        </>)
    }

    function Anexo({ arquivo }) {
        return <div className="cardArquivo card" style={{cursor: 'pointer'}}>
            <Row className="justify-content-end px-3">
                <div
                    className="checkbox-anexo p-1 py-0 rounded"
                    style={{cursor: 'pointer'}}
                    data-tip={`${getValues(resolverNomeFormulario(arquivo.id_arquivo_atendimento)) ? 'Desconsiderar' : 'Selecionar'} Anexo`}
                >
                    <Form.Check
                        type="checkbox"
                        {...register(resolverNomeFormulario(arquivo.id_arquivo_atendimento))}
                        data-tip={`${getValues(resolverNomeFormulario(arquivo.id_arquivo_atendimento)) ? 'Desconsiderar' : 'Selecionar'} Anexo`}
                        style={{cursor: 'pointer'}}
                    />
                </div>
            </Row>
            <Row className="my-3 justify-content-center">
                <FontAwesomeIcon size="4x" color="#E75242" title="anexo" icon={faFilePdf} />
            </Row>
            <Row className="justify-content-center" style={{ fontSize: '0.8rem', minHeight: '5vh' }} data-tip={arquivo.nome}>
                {formatarNomeArquivo(arquivo.nome) || 'Anexo sem nome'}
            </Row>
            <Button
                className="mt-1 btn-sm"
                data-tip=""
                onClick={(event) => {
                event.stopPropagation()
                window.open(arquivo.link, '_blank')
            }}>
                Baixar
            </Button>
            <Button variant="danger" className="mt-1 btn-sm" data-tip="" onClick={(event) => {
                event.stopPropagation()
                excluirArquivo(arquivo.id_arquivo_atendimento)
            }}>
                Excluir
            </Button>
        </div>
    }

    function formatarNomeArquivo(nome) {
        let nomeFormatado = nome
        if(nome.length > 61) {
            let extensao = nomeFormatado.split('.').at(-1)
            nomeFormatado = `${nomeFormatado.substring(0, 23)}...${extensao}`
        }
        return nomeFormatado
    }

    return <Form onSubmit={handleSubmit(enviarArquivosSelecionados)}>
        <ReactTooltip effect="solid"/>
        <DommusToggle
            title={`Enviar ${tipo == 'imagem' ? 'Imagens' : 'Anexos'}`}
            toggle={toggleEnviarArquivosAberto}
            className="labels"
            bodyClass="overflow-auto"
            callback={() => setToggleEnviarArquivosAberto(toggleAberto => !toggleAberto)}
        >
            {arquivos.length ? (<>
                <Row className={"justify-content-center" + ((isMobile && " fixed-height-attachment") || "")}>
                    {arquivos.sort((a, b) => a.nome > b.nome ? 1 : -1).map((arquivo, i) => (
                        <div
                          key={i}
                          id={'divFotos-' + i}
                          className={"divImg"}
                          onClick={() => marcarDesmarcarArquivo(arquivo.id_arquivo_atendimento)}
                          style={{ cursor: 'pointer' }}
                          data-tip={`${getValues(resolverNomeFormulario(arquivo.id_arquivo_atendimento)) ? 'Desconsiderar' : 'Selecionar'} ${tipo == 'imagem' ? 'Imagem' : 'Anexo'}`}
                        >
                            {tipo == 'imagem' ? <Imagem arquivo={arquivo}/> : <Anexo arquivo={arquivo}/>}
                        </div>
                    ))}
                </Row>
                <Row className="justify-content-end mr-0 mt-3">
                <Button type="submit" style={{ backgroundColor: 'var(--dommus-color)' }} className="border-0">
                    <Icon icon={sendOutlined} color="white"/> Enviar Selecionados
                </Button>
            </Row>
            </>) : (
                <Alert variant="warning" className="text-center">
                    <FontAwesomeIcon icon={faExclamationTriangle}/> {`${tipo == 'imagem'? 'Nenhuma imagem salva' : 'Nenhum anexo salvo'} até o momento.`}
                </Alert>
            )}
        </DommusToggle>
        <DommusToggle
            title={`Salvar ${tipo == 'imagem' ? 'Imagens' : 'Anexos'}`}
            toggle={toggleSalvarArquivosAberto}
            className="labels"
            bodyClass="overflow-auto"
            callback={() => setToggleSalvarArquivosAberto(toggleAberto => !toggleAberto)}
        >
            <Form>
                <Anexos
                    listaAnexos={arquivosSalvar}
                    setListaAnexos={setArquivosSalvar}
                    filesTypes={tipo == 'imagem' ? 'image/jpeg, image/png, image/gif, image/bmp, image/svg+xml, image/webp' : '*'}
                />
                <div className="area_btn_anexar">
                    <Button className="anexarImagem" onClick={() => salvarImagens()}>
                        Enviar <Icon icon={sendOutlined} color="white" />
                    </Button>
                </div>
            </Form>
        </DommusToggle>
    </Form>
}

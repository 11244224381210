import React, { useState, useRef } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import TokenInput from "react-customize-token-input";
import Swal from "sweetalert2";
import { Editor } from "@tinymce/tinymce-react";

import "react-customize-token-input/dist/react-customize-token-input.css";

import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import caAPI from "../../../../services/caAPI";
import Anexos from "../../../../components/Anexos";

export default function ModalEmail({
  assinatura,
  atendimento,
  AtualizarListaEmails,
  setOpenModalEmail,
}) {
  const [assuntoInput, setAssuntoInput] = useState();
  const [mensagemInput, setMensagemInput] = useState();
  const [copiaCortesia, setCopiaCortesia] = useState([]);
  const [copiaCortesiaOculta, setCopiaCortesiaOculta] = useState([]);
  const [destinatarios, setDestinatarios] = useState([
    atendimento.contato_destino,
  ]);
  const [listaAnexos, setListaAnexos] = useState([]);
  const [filesTypes] = useState("*")

  const editor = useRef();

  const handleSubmitForm = function () {
    if (!assuntoInput || !mensagemInput) {
      Swal.fire({
        titleText: "Atenção",
        text: "Preencha todos os campos obrigatórios",
        icon: "info",
      });
      return;
    }
    const data = {
      id_atendimento: atendimento.id,
      subject: assuntoInput,
      conteudo: mensagemInput,
      to: destinatarios,
      cc: copiaCortesia,
      bcc: copiaCortesiaOculta,
      attachments: listaAnexos,
    };
    caAPI.atendimento.email
      .enviar(data)
      .then((res) => {
        AtualizarListaEmails(atendimento.id);
        setOpenModalEmail(false);
      })
      .catch((e) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um problema ao enviar o email",
          icon: "error",
        });
      });
  };

  function handleEditorChange(content, editor) {
    setMensagemInput(content);
  }

  return (
    <>
      <Form.Group as={Row} controlId="formPara">
        <Form.Label column sm="1">
          Para:
          <DommusRequiredSpan />
        </Form.Label>
        <Col sm="11">
          <Form.Control
            type="mail"
            defaultValue={atendimento.contato_destino}
            value={atendimento.contato_destino}
            // readOnly
            onChange={(event) => setDestinatarios(event.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formCC">
        <Form.Label column sm="1">
          CC:{" "}
        </Form.Label>
        <Col sm="11">
          <TokenInput
            className="formCC"
            onTokensUpdate={(event) => {
              const novoCopiaCortesia = event.map((email) => email.value);
              setCopiaCortesia(novoCopiaCortesia);
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formCCO">
        <Form.Label column sm="1">
          CCO:{" "}
        </Form.Label>
        <Col sm="11">
          <TokenInput
            className="formCCO"
            onTokensUpdate={(event) => {
              const novoCopiaCortesiaOculta = event.map((email) => email.value);
              setCopiaCortesiaOculta(novoCopiaCortesiaOculta);
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formAssunto">
        <Form.Label column sm="1">
          Assunto:
          <DommusRequiredSpan />
        </Form.Label>
        <Col sm="11">
          <Form.Control
            type="text"
            placeholder="Digite o Assunto"
            value={assuntoInput}
            onChange={(event) => setAssuntoInput(event.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group controlId="formMensagem">
        <Form.Label>Anexos:</Form.Label>
        <Anexos listaAnexos={listaAnexos} setListaAnexos={setListaAnexos} filesTypes={filesTypes} />
      </Form.Group>
      <Form.Group controlId="formMensagem">
        <Form.Label>
          Mensagem: <DommusRequiredSpan />
        </Form.Label>
        <Editor
          apiKey="25mzf46lh9blkhbpnxuvuz2etjw5cqrt3r82xl0geig8fhj5"
          onEditorChange={handleEditorChange}
          value={mensagemInput}
          ref={editor}
          init={{
            language: "pt_BR",
            entity_encoding: "raw",
            // language_url: "../../../../public/langs/pt_BR.js",
            selector: "textarea#templateTitle",
            height: 200,
            menubar: "custom",
            plugins: [
              "advlist autolink lists link image",
              "charmap print preview anchor help",
              "searchreplace visualblocks code",
              "insertdatetime media table paste wordcount",
            ],
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            toolbar:
              "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | assigne | help  ",
            setup: (editor) => {
              editor.ui.registry.addButton("assigne", {
                tooltip: "Adjust Layout",
                icon: "edit-block",
                text: "Assinar",
                onAction: () => editor.insertContent(assinatura),
              });
            },
          }}
        />
      </Form.Group>
      <Button
        type="submit"
        className="btnEnviarEmail"
        onClick={() => {
          handleSubmitForm();
        }}
      >
        Enviar
      </Button>
    </>
  );
}
